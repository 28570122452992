/* begin general import */
import {
  Add16,
  Download16,
  OverflowMenuHorizontal24,
  SettingsAdjust16,
  TrashCan16,
  Upload16,
  Websheet16,
} from "@carbon/icons-react";
import { Dropdown, Menu, PaginationProps, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { APP_USER_ROUTE } from "config/route-consts";
import { AppUser, AppUserFilter } from "models/AppUser";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceIdFilterMaster,
  Button,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
import { appUserRepository } from "repositories/app-user-repository";
import { profileRepository } from "repositories/profile-repository";
import { detailService } from "services/page-services/detail-service";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import AppUserDetailDrawer from "../AppUserDetail/AppUserDetailDrawer";
import AppUserAdvanceFilter from "./AppUserAdvanceFilter";
import { Status, StatusFilter } from "models/Status";
import { Organization, OrganizationFilter } from "models/Organization";
import { IdFilter } from "react3l-advanced-filters";
import { SYSTEM_ADMIN } from "config/consts";
import { renderMasterIndex } from "helpers/table";
import ChangePass from "pages/Authentication/ChangePass/ChangePass";
import InputSearch from "components/InputSearch";
import { RoleType } from "models/RoleType";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import TruncateCell from "components/TruncateCell";
import { importExportService } from "services/page-services/import-export-service";
/* end individual import */

function AppUserMaster() {
  const [translate] = useTranslation();
  const [modelFilter, dispatch] = queryStringService.useQueryString(
    AppUserFilter,
    { skip: 0, take: 10 }
  );

  const firstLoad = React.useRef<boolean>(true);
  const [user, setUser] = React.useState<AppUser>();

  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (firstLoad.current) {
      profileRepository.get().subscribe((res: any) => {
        setUser(res);
        firstLoad.current = false;
      });
    }
  }, []);

  const {
    value: filter,
    handleChangeAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      appUserRepository.list,
      appUserRepository.count,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    selectedRowKeys,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    appUserRepository.delete,
    appUserRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    APP_USER_ROUTE,
    handleAction
  );

  const {
    model: changePassModel,
    dispatch: dispatchChangePassModal,
    isOpenDetailModal: isOpenDetailChangePassModal,
    handleOpenDetailModal: handleOpenDetailChangePassModal,
    handleCloseDetailModal: handleCloseDetailChangePassModal,
    handleSaveModel: handleSaveChangePassModel,
    loadingModel: loadingChangePassModel,
    handleChangeSingleField: handleChangeSingleFieldChangePass,
  } = detailService.useDetailModal(
    AppUser,
    appUserRepository.get,
    appUserRepository.changePassword,
    handleLoadList
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
    handleChangeSelectField,
  } = detailService.useDetailModal(
    AppUser,
    appUserRepository.get,
    appUserRepository.save,
    handleLoadList
  );

  const isAdmin = React.useMemo(() => {
    return user?.roleType?.code === SYSTEM_ADMIN ? true : false;
  }, [user?.roleType?.code]);

  const { handleListExport, handleExportTemplateList } =
    importExportService.useExport();

  const {
    loading: loadingImport,
    handleImportList,
    ref: importButtonRef,
    handleClick,
  } = importExportService.useImport(handleResetList);

  const menuAction = React.useCallback(
    (id: number, appUser: AppUser) => (
      <Menu>
        {isAdmin && (
          <Menu.Item key="1">
            <div
              className="ant-action-menu text-center"
              onClick={() => handleOpenDetailChangePassModal(id)}
            >
              {translate("general.actions.changePass")}
            </div>
          </Menu.Item>
        )}
        {isAdmin && (
          <Menu.Item key="2">
            <div
              className="ant-action-menu text-center"
              onClick={() => handleOpenDetailModal(id)}
            >
              {translate("general.actions.edit")}
            </div>
          </Menu.Item>
        )}

        {isAdmin && !appUser?.used && (
          <Menu.Item key="3">
            <div
              className="ant-action-menu text-center"
              onClick={handleDeleteItem(appUser)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [
      isAdmin,
      translate,
      handleDeleteItem,
      handleOpenDetailChangePassModal,
      handleOpenDetailModal,
    ]
  );

  const pagination: PaginationProps = tableService.usePagination(filter, count);

  const columns: ColumnProps<AppUser>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...params: [number, AppUser, number]) {
          const index = renderMasterIndex<AppUser>(pagination)(...params);
          return (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "displayName"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.displayName")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].displayName),
        dataIndex: nameof(list[0].displayName),
        sorter: true,
        width: "15%",
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].displayName)
        ),
        render(...params: [string, AppUser, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "email"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.email")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].email),
        dataIndex: nameof(list[0].email),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].email)
        ),
        width: "15%",
        render(...params: [string, AppUser, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "phone"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.phone")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].phone),
        dataIndex: nameof(list[0].phone),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].phone)
        ),
        width: "10%",
        render(...params: [string, AppUser, number]) {
          return <TruncateCell renderContent={params[0]} />;
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "organization"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.organization")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].organization),
        dataIndex: nameof(list[0].organization),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].organization)
        ),
        render(...params: [Organization, AppUser, number]) {
          return (
            <TruncateCell
              renderContent={params[0]?.code}
              renderExtraContent={params[0]?.name}
            />
          );
        },
      },
      {
        title: () => {
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.roleType")}
            />
          );
        },
        key: nameof(list[0].roleType),
        dataIndex: nameof(list[0].roleType),
        width: "10%",
        render(...params: [RoleType, AppUser, number]) {
          return <TruncateCell renderContent={params[0]?.name} />;
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("appUsers.status")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<AppUser, AppUserFilter>(
          filter,
          nameof(list[0].status)
        ),
        width: 150,
        render(...params: [Status, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },

      {
        title: translate("general.actions.label"),
        key: "action",
        dataIndex: nameof(list[0].id),
        width: 80,
        align: "center",
        render(id: number, appUser: AppUser) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              {isAdmin && (
                <Dropdown
                  overlay={menuAction(id, appUser)}
                  trigger={["click"]}
                  placement="bottom"
                  arrow
                >
                  <OverflowMenuHorizontal24 />
                </Dropdown>
              )}
            </div>
          );
        },
      },
    ],
    [filter, isAdmin, list, menuAction, pagination, translate]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loadingList}>
        <div className="page-content">
          <PageHeader
            title={translate("appUsers.master.subHeader")}
            breadcrumbItems={[
              translate("appUsers.master.header"),
              translate("appUsers.master.subHeader"),
            ]}
          />

          <div className="page page-master m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--xs p-b--xs">
              {translate("appUsers.master.title")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"appUsers"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={() => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={
                            filter[nameof(list[0].organizationId)]["equal"]
                          }
                          placeHolder={translate(
                            "appUsers.placeholder.organization"
                          )}
                          classFilter={OrganizationFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].organization),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={appUserRepository.filterListOrganization}
                          title={translate("appUsers.organization")}
                        />
                      </div>
                      <div className="">
                        <AdvanceIdFilterMaster
                          value={filter[nameof(list[0].statusId)]["equal"]}
                          placeHolder={translate("appUsers.placeholder.status")}
                          classFilter={StatusFilter}
                          onChange={handleChangeSelectFilter({
                            fieldName: nameof(list[0].status),
                            fieldType: "equal",
                            classFilter: IdFilter,
                          })}
                          getList={appUserRepository.filterListStatus}
                          title={translate("appUsers.status")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter?.search}
                      classFilter={AppUserFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <input
                        ref={importButtonRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onClick={handleClick}
                        onChange={handleImportList(appUserRepository.import)}
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Download16 />}
                        onClick={handleListExport(
                          filter,
                          appUserRepository.export
                        )}
                        className="btn--xl btn-grey ml-3"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Upload16 />}
                        onClick={() => {
                          importButtonRef.current.click();
                        }}
                        className="btn--xl btn-grey ml-3"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Websheet16 />}
                        onClick={handleExportTemplateList(
                          appUserRepository.exportTemplate
                        )}
                        className="btn--xl btn-grey ml-3"
                      />
                      {isAdmin && (
                        <Button
                          type="primary"
                          className="btn--lg"
                          icon={<Add16 />}
                          onClick={() => handleOpenDetailModal(null)}
                        >
                          {translate("general.actions.create")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                scroll={{ y: 500 }}
                bordered
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>

      {visible && (
        <AppUserAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}

      {isOpenDetailChangePassModal && (
        <ChangePass
          visible={isOpenDetailChangePassModal}
          dispatch={dispatchChangePassModal}
          loading={loadingChangePassModel}
          model={changePassModel}
          isAdminChangePassword
          handleSave={handleSaveChangePassModel}
          handleChangeSingleField={handleChangeSingleFieldChangePass}
          handleCancel={handleCloseDetailChangePassModal}
        />
      )}
      {isOpenDetailModal && (
        <AppUserDetailDrawer
          model={model}
          visible={isOpenDetailModal}
          handleSave={handleSaveModel}
          handleCancel={handleCloseDetailModal}
          handleChangeSingleField={handleChangeSingleField}
          handleChangeSelectField={handleChangeSelectField}
          dispatch={dispatchModal}
          loading={loadingModel}
          visibleFooter={true}
        />
      )}
    </>
  );
}
export default AppUserMaster;
