import { Tooltip } from "antd";
import classNames from "classnames";
import { useMemo } from "react";

export interface TruncateCellProps {
  renderContent: string | number;
  renderExtraContent?: string | number;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  maxLengthItem?: number;
  /** Option to align display value */
  position?: "left" | "right" | "center";
}

function TruncateCell(props: TruncateCellProps) {
  const {
    className,
    renderContent,
    renderExtraContent,
    maxLengthItem,
    position,
    onClick,
  } = props;

  const mergedContent = useMemo(() => {
    if (renderContent) {
      if (renderExtraContent) return `${renderContent} - ${renderExtraContent}`;
      else return renderContent;
    } else return "";
  }, [renderContent, renderExtraContent]);

  return (
    <div
      className={classNames(
        className,
        `layout-cell-position-${position}`,
        "text-truncate p-x--xxxs"
      )}
      style={{ fontSize: 12 }}
      onClick={onClick}
    >
      {String(mergedContent)?.length > maxLengthItem ? (
        <Tooltip title={mergedContent} placement={"topLeft"}>
          {mergedContent}
        </Tooltip>
      ) : (
        <span>{mergedContent}</span>
      )}
    </div>
  );
}

TruncateCell.defaultProps = {
  maxLengthItem: 10,
  position: "left",
};

export default TruncateCell;
