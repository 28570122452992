/* begin general import */
import { Col, Radio, Row, Spin } from "antd";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import FormItem from "react3l-ui-library/build/components/FormItem";
import {
  Button,
  DatePicker,
  InputNumber,
  OneLineText,
  Select,
  StandardTable,
} from "react3l-ui-library";
import { Close16, Save16 } from "@carbon/icons-react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { webService } from "services/common-services/web-service";
import {
  detailService,
  ModelActionEnum,
} from "services/page-services/detail-service";
import { CourseSection } from "models/CourseSection";
import { fieldService } from "services/page-services/field-service";
import { ROLL_CALL_DETAIL_ROUTE } from "config/route-consts";
import React from "react";
import { queryStringService } from "services/page-services/query-string-service";
import { ColumnsType } from "antd/lib/table";
import { RollCall, RollCallFilter } from "models/RollCall";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { Student } from "models/Student";
import { RollCallType } from "models/RollCallType";
import { rollCallRepository } from "repositories/roll-call-repository";
import { useHistory } from "react-router";
import { finalize } from "rxjs/operators";
import appMessageService from "services/common-services/app-message-service";
import { AxiosError } from "axios";
import { Moment } from "moment";
import { STANDARD_DATE_FORMAT_INVERSE } from "config/consts";
import LayoutHeader from "components/LayoutHeader";
import LayoutCell from "components/LayoutCell";
import { formatNumber } from "helpers/number";
import { AppUserFilter } from "models/AppUser";

/* end individual import */

function CourseSectionRollCallDetail() {
  const [translate] = useTranslation();

  // use hook
  const { model, dispatch } =
    detailService.useModel<CourseSection>(CourseSection);

  const { rollCallId }: any =
    queryStringService.useGetQueryString("rollCallId");

  const { courseSectionId } =
    queryStringService.useGetQueryString("courseSectionId");

  const { isClone } = queryStringService.useGetQueryString("isClone");

  const isDetail = React.useMemo(() => rollCallId !== null, [rollCallId]);

  const [subscription] = webService.useSubscription();

  const [firstContent, setFirstContent] = React.useState<RollCall>({
    student: new Student(),
    rollCallType: new RollCallType(),
    studentId: 0,
  });

  React.useEffect(() => {
    if (isDetail) {
      if (isClone) {
        subscription.add(
          rollCallRepository.clone({ id: rollCallId }).subscribe({
            next: (res) =>
              dispatch({ type: ModelActionEnum.SET, payload: res }),
            error: (_err) => {},
          })
        );
      } else
        subscription.add(
          rollCallRepository.get(rollCallId).subscribe({
            next: (res) =>
              dispatch({ type: ModelActionEnum.SET, payload: res }),
            error: (_err) => {},
          })
        );
    } else {
      subscription.add(
        rollCallRepository
          .getDraft({ courseSectionId: courseSectionId })
          .subscribe({
            next: (res) =>
              dispatch({ type: ModelActionEnum.SET, payload: res }),

            error: (_err) => {},
          })
      );
    }
  }, [courseSectionId, dispatch, isClone, isDetail, rollCallId, subscription]);

  const {
    handleChangeDateField,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
  } = fieldService.useField(model, dispatch);

  const [loading, setLoading] = React.useState<boolean>(false);
  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  const history = useHistory();
  const handleGoRollCallCourseSection = React.useCallback(() => {
    return () => {
      history.push(
        `${ROLL_CALL_DETAIL_ROUTE}?courseSectionId=${courseSectionId}`
      );
    };
  }, [courseSectionId, history]);

  const handleSaveModel = React.useCallback(() => {
    setLoading(true);
    subscription.add(
      rollCallRepository
        .save(model)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: (item: RollCall) => {
            handleChangeAllField(item); // setModel
            notifyUpdateItemSuccess();
            handleGoRollCallCourseSection()(); // go master
          },
          error: (error: AxiosError<RollCall>) => {
            if (error.response && error.response.status === 400)
              handleChangeAllField(error.response?.data);
            notifyUpdateItemError();
          },
        })
    );
  }, [
    handleChangeAllField,
    handleGoRollCallCourseSection,
    model,
    notifyUpdateItemError,
    notifyUpdateItemSuccess,
    subscription,
  ]);

  const [filter, dispatchFilter] = React.useReducer<
    React.Reducer<RollCallFilter, FilterAction<RollCallFilter>>
  >(filterReducer, new RollCallFilter());

  const checkAllEqualRollCallTypeId = React.useCallback(
    (contents: RollCall[]) => {
      if (contents.length === 0) return true; // Nếu không có dữ liệu, trả về true (vì không có gì để so sánh)

      const firstRollCallTypeId = contents[0].rollCallTypeId; // Lấy giá trị rollCallTypeId đầu tiên làm tham chiếu
      return contents.every(
        (item) => item.rollCallTypeId === firstRollCallTypeId
      );
    },
    []
  );

  const mergedList = React.useMemo(() => {
    const contents = model?.rollCallContents
      ? _.cloneDeep(model?.rollCallContents)
      : [];

    if (contents?.length > 0) {
      const checkIfAllEqualRollCallTypeId =
        checkAllEqualRollCallTypeId(contents);
      if (checkIfAllEqualRollCallTypeId) {
        firstContent.rollCallTypeId = contents[0]?.rollCallTypeId;
      } else firstContent.rollCallTypeId = 0;
      const newContents = [firstContent, ...contents];
      setFirstContent(firstContent);
      return newContents;
    }
  }, [checkAllEqualRollCallTypeId, firstContent, model?.rollCallContents]);

  const { handleChangeListField } = fieldService.useField(model, dispatch);

  const handleAttendanceChange = React.useCallback(
    (index: number, value) => {
      const contents = model?.rollCallContents
        ? _.cloneDeep(model?.rollCallContents)
        : [];

      if (contents?.length > 0) {
        if (index > -1) {
          const currentContent = _.cloneDeep(contents[index]);
          contents[index].rollCallTypeId =
            currentContent.rollCallTypeId === value ? 0 : value;
        } else {
          contents.forEach((content: RollCall) => {
            content.rollCallTypeId = value;
          });
          setFirstContent({ ...firstContent, rollCallTypeId: value });
        }
        handleChangeListField({
          fieldName: nameof(model.rollCallContents),
        })(contents);
      }
    },
    [firstContent, handleChangeListField, model.rollCallContents]
  );

  const columns: ColumnsType<RollCall> = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
            position="center"
          />
        ),
        key: "index",
        width: 60,
        render(...[, , index]: [any, RollCall, number]) {
          return index !== 0 ? (
            <LayoutCell orderType="left" tableSize="md" position="center">
              <OneLineText value={formatNumber(index)} />
            </LayoutCell>
          ) : undefined;
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.studentCode"
            )}
          />
        ),
        key: "studentCode",
        dataIndex: "student",
        render(...params: [Student, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.code} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.studentName"
            )}
          />
        ),
        key: "studentName",
        dataIndex: "student",
        render(...params: [Student, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.name} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.className")}
          />
        ),
        key: "className",
        dataIndex: "student",
        render(...params: [Student, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.className} countCharacters={35} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.gender")}
          />
        ),
        key: "gender",
        dataIndex: "student",
        render(...params: [Student, RollCall, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText
                value={params[0]?.gender?.name}
                countCharacters={35}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.present")}
          />
        ),
        key: "present",
        dataIndex: "rollCallType",
        render(...params: [RollCallType, RollCall, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].rollCallType)
                )}
                message={params[1].errors?.rollCallType}
              >
                <Radio
                  checked={params[1].rollCallTypeId === 1}
                  onClick={() => handleAttendanceChange(params[2] - 1, 1)}
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.late")}
          />
        ),
        key: "late",
        dataIndex: "rollCallType",
        render(...params: [RollCallType, RollCall, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].rollCallType)
                )}
                message={params[1].errors?.rollCallType}
              >
                <Radio
                  checked={params[1].rollCallTypeId === 2}
                  onClick={() => handleAttendanceChange(params[2] - 1, 2)}
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("courseSections.courseSectionRollCalls.absent")}
          />
        ),
        key: "absent",
        dataIndex: "rollCallType",
        render(...params: [RollCallType, RollCall, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].rollCallType)
                )}
                message={params[1].errors?.rollCallType}
              >
                <Radio
                  checked={params[1].rollCallTypeId === 3}
                  onClick={() => handleAttendanceChange(params[2] - 1, 3)}
                />
              </FormItem>
            </div>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate(
              "courseSections.courseSectionRollCalls.absentWithPermission"
            )}
          />
        ),
        key: "absentWithPermission",
        dataIndex: "rollCallType",
        render(...params: [RollCallType, RollCall, number]) {
          return (
            <div className="m-b--xxs m-r--sm m-l--xxs ">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].rollCallType)
                )}
                message={params[1].errors?.rollCallType}
              >
                <Radio
                  checked={params[1].rollCallTypeId === 4}
                  onClick={() => handleAttendanceChange(params[2] - 1, 4)}
                />
              </FormItem>
            </div>
          );
        },
      },
    ],
    [handleAttendanceChange, translate]
  );

  return (
    <Spin spinning={loading}>
      <div className="page-content page-content-dxg" id="page-content-dxg-id">
        <div className="info-component m-l--sm m-r--xxl mb-3">
          <span>{translate("courseSections.master.header")}</span>
          <span> &gt; </span>
          <span>
            {translate(
              "courseSections.courseSectionRollCalls.detail.headerTitle"
            )}
          </span>
          <span> &gt; </span>
          <span className="info-primary">
            {translate("courseSections.courseSectionRollCalls.rollCall")}
          </span>
        </div>
        <div className="page page-detail">
          <div className="d-flex">
            <h5 className="font-bold page-title m-l--sm m-t--sm">
              {`${translate(
                "courseSections.courseSectionRollCalls.detail.headerTitle"
              )} ${model?.courseSection?.code} - ${model?.courseSection?.name}`}
            </h5>
          </div>
          <div className="page-detail__content">
            <div className="page-detail__title p-b--lg">
              {translate("courseSections.detail.generalTitle")}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.lessonDate)
                  )}
                  message={model.errors?.lessonDate}
                >
                  <DatePicker
                    label={translate(
                      "courseSections.courseSectionRollCalls.lessonDate"
                    )}
                    value={model.lessonDate}
                    type={0}
                    placeholder={translate("general.placeholder.autoGen")}
                    onChange={(date: Moment) =>
                      handleChangeDateField({
                        fieldName: nameof(model.lessonDate),
                      })(date?.startOf("day"))
                    }
                    dateFormat={[STANDARD_DATE_FORMAT_INVERSE]}
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.numberOfCreditHours)
                  )}
                  message={model.errors?.numberOfCreditHours}
                >
                  <InputNumber
                    label={translate(
                      "courseSections.courseSectionRollCalls.indexOfLesson"
                    )}
                    type={0}
                    value={model.indexOfLesson}
                    placeHolder={translate("general.placeholder.autoGen")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.indexOfLesson),
                    })}
                  />
                </FormItem>
              </Col>
              <Col lg={6} className="m-b--sm">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.appUser)
                  )}
                  message={model.errors?.appUser}
                >
                  <Select
                    label={translate(
                      "rollCalls.courseSectionRollCalls.appUser"
                    )}
                    type={0}
                    classFilter={AppUserFilter}
                    searchProperty={"displayName"}
                    placeHolder={translate(
                      "rollCalls.courseSectionRollCalls.appUser"
                    )}
                    getList={rollCallRepository.singleListAppUser}
                    onChange={handleChangeSelectField({
                      fieldName: nameof(model.appUser),
                    })}
                    value={model.appUser}
                    render={(item) => item && item?.displayName}
                    disabled
                  />
                </FormItem>
              </Col>
            </Row>

            <div className="p-t--lg p-b--space-ultra-wire">
              <div className="d-flex justify-content-between align-items-center">
                <div className="page-detail__title p-b--lg">
                  {translate("rollCalls.courseSectionStudentMappings.title")}
                </div>
              </div>
              <StandardTable
                rowKey={"studentId"}
                columns={columns}
                dataSource={mergedList}
                isDragable={true}
                tableSize={"md"}
                scroll={{ y: 500 }}
                bordered
              />
            </div>
          </div>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg btn-red"
                icon={<Close16 />}
                onClick={handleGoRollCallCourseSection()}
              >
                {translate("general.actions.close")}
              </Button>

              <Button
                type="primary"
                className="btn--lg btn-blue ml-3"
                icon={<Save16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </Spin>
  );
}

export default CourseSectionRollCallDetail;
